import type {
  SimpleTableColumn,
  SimpleTableHeaderSlot,
  SimpleTableItemSlot,
} from './types'

export const getTemplate = (column: SimpleTableColumn, result: any) => {
  if ('cell' in column && typeof column.cell === 'function') {
    return column.cell(result[column.id], { result })
  }
  return result[column.id]
}

export const createHeaderSlotProps = (
  column: SimpleTableColumn,
): SimpleTableHeaderSlot => {
  return {
    id: column.id,
    value: column.header,
  }
}

export const createItemSlotProps = (
  column: SimpleTableColumn,
  item: any,
): SimpleTableItemSlot => {
  return {
    id: column.id,
    item,
    value: getTemplate(column, item),
  }
}
